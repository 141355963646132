import React from 'react';
import _ from 'lodash';

import components, {Layout, Service2Block, ClientSlide} from '../components/index';
import {htmlToReact, safePrefix} from '../utils';

export default class About extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
            <div className="outer">
              <div className="inner">
                <article className="post page post-full">
                  <section className="about-hero">
                  <video muted loop id="myVideo" controls>
                    <source src="https://res.cloudinary.com/dshbwx0n7/video/upload/v1582727351/mv-video-intro_vqyss0.mp4" type="video/mp4"></source>
                  </video>  
                  </section>
{/*                   <header className="post-header inner-small">
                    <h1 className="post-title line-top">{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
                  </header> */}
                  <hr className="own-hr"/>
                  <div className="post-content inner-small about-content">
                    {htmlToReact(_.get(this.props, 'pageContext.html'))}
                  </div>
                  <hr className="own-hr"/>
                  <Service2Block {...this.props}/>
                  <ClientSlide {...this.props}/>
                </article>
                
              </div>
            </div>
            </Layout>
        );
    }
}
